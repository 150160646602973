<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="排口名称">
                <el-input @change="onQuerySubmit" v-model="query.equipment_name" placeholder="排口名称"></el-input>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker v-model="dayValue" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
              </el-form-item>
            </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="已处理记录" name="1">
              <el-table :data="res.data" stripe style="width: 100%">
                <el-table-column prop="equipment_name" label="排口名称" min-width="180">
                </el-table-column>
                <el-table-column prop="handle_name" label="处理人员">
                </el-table-column>    
                <el-table-column prop="handle_status" label="处理情况">
                </el-table-column>
                <el-table-column prop="remark" label="备注信息" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间" min-width="200">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="200" v-if="permission.apiHandleHandleDetail || permission.apiInspectionFormType">
                  <template slot-scope="scope">
                    <el-button @click="openDrawer(scope.row)" type="text" size="small" v-if="permission.apiHandleHandleDetail">查看预警信息</el-button>
                    <el-button type="text" size="small" @click="goToinfo(scope.row)" v-if="permission.apiInspectionFormType">查看台账详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="不需要处理记录" name="0">
              <el-table :data="res.data" stripe style="width: 100%">
                <el-table-column prop="equipment_name" label="排口名称">
                </el-table-column>
                <el-table-column prop="user_name" label="处理人员">
                </el-table-column>    
                <el-table-column prop="handle_status" label="处理情况">
                </el-table-column>
                <el-table-column prop="remark" label="备注信息">
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间">
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="14" @current-change="changeCurrent" :current-page="query.page" :total="res.total"></el-pagination>
    </el-card>
    <!-- 查看预警信息 -->
    <el-drawer title="预警信息" :visible.sync="drawer" direction="rtl" :wrapperClosable="false" size="60%">
      <el-card class="box-card">
          <el-table :data="drawerList.data" stripe style="width: 100%">
            <el-table-column prop="equipment_name" label="排口名称">
            </el-table-column>
            <el-table-column prop="factor_name" label="因子名称">
            </el-table-column>
            <el-table-column label="预警类型">
              <template slot-scope="scope">
                {{scope.row.error_type === 1 ? '超标监测' : '固定值'}}
              </template>
            </el-table-column>    
            <el-table-column label="超标类型">
              <template slot-scope="scope">
                {{scope.row.error_way === 1 ? '高' : '低'}}
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="排放标准">
            </el-table-column>
            <el-table-column prop="data_time" label="上传时间">
            </el-table-column>
            <el-table-column prop="last_time" label="最新时间">
            </el-table-column>
          </el-table>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="drawerChangeCurrent" :current-page="drawerQuery.page" :total="drawerList.total"></el-pagination>
      </el-card>
    </el-drawer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiHandleHandleList, apiInspectionSignInDetail, apiHandleHandleDetail } from "src/api/index";
import md5 from 'js-md5';
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      query: {
        status: 1,
        equipment_name: '',
        start_time: '',
        end_time: '',
        page: 1,
        logotype: 'earlyhandle'
      },
      dayValue: '',
      res: {},
      activeName: '1',
      drawerList: {},
      drawerQuery: {
        id: 0,
        page: 1,
      }
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'earlyhandle') {
      _this.query =  query;
      _this.dayValue = [query.start_time, query.end_time]
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getdata'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询处理记录数据
    onQuerySubmit() {
      this.query.start_time = this.dayValue[0];
      this.query.end_time = this.dayValue[1];
      this.query.page = 1;
      let route = this.$router.history.current.path + '?status=' + this.query.status + '&page=' + this.query.page + '&equipment_name=' + this.query.equipment_name + '&start_time=' + this.query.start_time + '&end_time=' + this.query.end_time;
      this.$router.replace(route);
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route = this.$router.history.current.path + '?status=' + this.query.status + '&page=' + this.query.page + '&equipment_name=' + this.query.equipment_name + '&start_time=' + this.query.start_time + '&end_time=' + this.query.end_time;
      this.$router.replace(route);
    },
    //获取处理记录列表数据
    getdata() {
      const _this = this;
      _this.loading = true;
      apiHandleHandleList(_this.query)
      .then((res) => {
        _this.res = res;
        _this.loading = false;
      })
      .catch((err) => {
        _this.loading = false;
        _this.error(err);
      })
    },
    //切换顶部菜单
    handleClick() {
      this.query.status = parseInt(this.activeName);
      this.onQuerySubmit();
    },
    //打开抽屉
    openDrawer(item) {
      this.drawer = true;
      this.drawerQuery.id = item.id;     
      this.drawerList = {};
      this.drawerChangeCurrent(1);
    },
    //抽屉切换分页
    drawerChangeCurrent(item) {
      this.drawerQuery.page = item;
      this.getDrawerInfo();
    },
    //获取预警信息详情
    getDrawerInfo() {
      const _this = this;
      apiHandleHandleDetail(_this.drawerQuery)
      .then((res) => {
        _this.drawerList = res;
      })
      .catch((err) => {
        _this.error(err);
      })
    },
    //跳转查看台账详情
    goToinfo(item) {
      const _this = this;
      apiInspectionSignInDetail({id: item.form_id})
      .then((res) => {    
        this.$localStorage.set(md5("query"), JSON.stringify(this.query));  
        this.$router.push('/admin/early/handle-detail/table/' + item.form_id)
      })
      .catch((err) => {
        _this.error(err); 
      })
    },
    //错误信息提示
    error(err) {
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      } 
    },
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
</style>